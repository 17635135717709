<template>
  <div class="container">
    <PublicHeader :totalName="$t('recharge').title" />
    <div class="content">
      <div class="qrcode">
        <img :src="info.QRCode" alt="" />
      </div>

      <div style="font-size: 14px;color: #e143ac">{{ $t('recharge').bc }}</div>
      <h2>{{ $t('recharge').address }}</h2>
      <div class="link">{{ info.CurrencyUrl }}</div>
      <van-button round plain type="info" size="normal" color="#e143ac" @click="getShareLink">{{
        $t('recharge').copy
      }}</van-button>
    </div>
    <div class="form">
      <div>{{ $t('recharge').num }}：</div>
      <van-field v-model="Amount" type="number" />
      <div>{{ $t('recharge').Upload }}：</div>
      <div class="img-box">
        <vue-core-image-upload
          class="btn btn-primary"
          :crop="false"
          @imageuploaded="imageuploaded"
          :max-file-size="5242880"
          :url="actionApi"
        >
          <img class="upImage" v-if="PayImage" :src="PayImage" alt="" />
          <div class="upImage plus" v-else>
            <van-icon name="photo-o" size="30" />
          </div>
        </vue-core-image-upload>
        <!-- <van-uploader
          preview-size="120"
          :preview-image="false"
          :after-read="justAfterRead"
        >
          <img class="upImage" v-if="PayImage" :src="PayImage" alt="" />
        </van-uploader> -->
      </div>

      <van-button type="info" round block color="#8e1473" @click="handleSub">{{
        $t('recharge').Submit
      }}</van-button>
    </div>

    <span id="InvitationCode">{{ info.CurrencyUrl }}</span>
  </div>
</template>

<script>
import PublicHeader from '@/components_pc/publicHeaderNew'
import transactionApi from '@/api/transaction'
import VueCoreImageUpload from 'vue-core-image-upload'
export default {
  components: {
    PublicHeader,
    VueCoreImageUpload
  },
  data() {
    return {
      actionApi: window.baseURL + '/api/Captcha/file',
      info: {},
      files: [],
      Amount: null,
      PayImage: ''
    }
  },
  mounted() {
    this.info = this.$route.query.info
  },
  methods: {
    imageuploaded(res) {
      this.PayImage = res
    },
    async handleSub() {
      if (!this.Amount) {
        this.$toast(this.$t('recharge').tip)
        return false
      }
      if (this.PayImage === '') {
        this.$toast(this.$t('recharge').tip1)
        return false
      }
      const form = {
        RechargeChannelId: this.info.Id,
        Amount: Number(this.Amount),
        PayImage: this.PayImage
      }
      await transactionApi.upper(form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.$router.push({ name: 'rechargeHistory' })
      }, 1000)
    },
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.PayImage = await transactionApi.upImage(formdata)
    },
    getShareLink() {
      setTimeout(() => {
        this.$toast({
          type: 'success',
          message: this.$t('recharge').copySuc,
          className: 'message',
          duration: '1000'
        })
      }, 200)
      const range = document.createRange()
      range.selectNode(document.getElementById('InvitationCode'))
      const selection = window.getSelection()
      if (selection.rangeCount > 0) selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeRange(range)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;
  margin: 0 25%;
  .content {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .qrcode {
      padding: 5px;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      margin-bottom: 20px;
      img {
        width: 120px;
        height: 120px;
      }
    }
    .link {
      margin-bottom: 20px;
      font-size: 14px;
    }
  }
  .form {
    padding: 15px;
    div {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .img-box {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    .van-field {
      border-radius: 5px;
    }
    .upImage {
      width: 120px;
      height: 120px;
      border: 1px solid #fff;
      border-radius: 4px;
      overflow: hidden;
    }
    .plus {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
#InvitationCode {
  position: absolute;
  left: -100vw;
}
</style>
